import React from 'react';
import { NextPage } from "next";
import Link from 'next/link';
import { ChevronRightIcon } from "@heroicons/react/outline";

interface SimpleCenterCTAProps {
  topHeading: string;
  heading: string;
  description: string;
}

const SimpleCenterCTA: NextPage<SimpleCenterCTAProps> = ({ topHeading, heading, description }) => {
    return (
      <>
        <div className="bg-dark-gradient bg-blend-screen flex flex-wrap rounded-xl max-w-7xl mx-auto p-10">
          <div className="grid grid-cols-2 md:grid-cols-4">
            <div className="w-full z-10 col-span-2">
              <h1 className="text-header-mbl md:text-header font-sourcesans relative">
                <div className="text-secondary-500">
                  <div className="text-white">{topHeading}<span className="text-orange mr-2"><br className='hidden md:block'/> {heading}</span></div>
                  
                </div>
              </h1>
              <p className="text-white md:text-body text-body-mbl mt-4">
                {description}
              </p>
            </div>
            <div className="flex md:justify-end col-span-2 items-center sm:justify-around mt-6 md:mt-0">
              <Link href={`/contact`} passHref>
                <button className="whitespace-nowrap inline-flex items-center justify-center md:px-12 px-6 py-3 border-2 border-transparent rounded-full shadow-sm text-h3 font-montserratB text-white">
                  {`LET'S GET STARTED`} &nbsp;
                  <ChevronRightIcon
                    className="h-4 w-4"
                    style={{ strokeWidth: "3px" }}
                    aria-hidden="true"
                  />
                </button>
              </Link>
            </div>
          </div>
        </div>
      </>
      );
}

export default SimpleCenterCTA;